// CSS
import "./VideoPlayer.css";

// Video Player
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';

const VideoPlayer = () => {
  return (
    <div className="videoContainer p-md-5">
      <Player
        playsInline
        poster="/img/projects/autoLavagem.webp"
        src="/img/projectPage/autoLavagem/AutoLavagemWebsite.mp4"
        width="100%"
      >
        <LoadingSpinner />
        <BigPlayButton position="center"/>
      </Player>
    </div>
  )
}

export default VideoPlayer