// CSS
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className='not-found-container d-flex justify-content-center'>
      <div className="col-12 col-md-7">
        <h1>Erro 404</h1>
        <p>A página que você está procurando pode ter sido removida, ter tido o nome trocado, ou pode estar temporariamente inacessível.</p>
        <p>Voltar para o <a href="/" className="link">início</a>.</p>
      </div>
    </div>
  )
}

export default NotFound


