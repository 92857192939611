// CSS
import "./MyServices.css";

// Hooks
import { useLocation } from 'react-router-dom';
import { useState, useLayoutEffect } from "react";

// Axios
import axios from "axios";

// Config
import CONFIG from "../../config/config";

// Components
import { PricingCard, Alert, Loader} from "../../components";

const MyServices = () => {
  const [usersService, setUsersService] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState({})

  const email = localStorage.getItem('email');

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "usersService") setUsersService(data);
        setIsLoading(false);
  
      } catch (error) {
        setNotification({title: "Erro", message: "Erro ao carregar serviços", type: "danger"});
      }
    }

    fetchData(`/users/services/${email}`, "usersService");
  }, []);

  const location = useLocation();

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <div className="container mt-3">
        {location?.state?.title && <Alert title={location?.state?.title} message={location?.state?.message} type={location?.state?.type ? location?.state?.type : "success"} />}

        <h1>Meus serviços</h1>
        
        <div className="cards-container d-flex flex-wrap justify-content-md-start justify-content-center">
          {isLoading ? <Loader /> : (
            <>
              {usersService && usersService.map((userService) => (
                <PricingCard key={userService._id} service={userService} myServices={true} />
              ))}
            </>
          )}
          {usersService.length === 0 && <p>Nenhum serviço encontrado...</p>}
        </div>
      </div>
    </>
  )
}

export default MyServices