const usePreventContextmenu = () => {
  document.onkeydown = (e) => {
    if (e.keyCode == 123) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'I') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'C') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'J') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.key == 'U') {
      e.preventDefault();
    }
  };

  document.addEventListener('contextmenu', function(e) {
    e.preventDefault();  
  });
}

export { usePreventContextmenu }