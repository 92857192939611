// MUI
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// Hooks
import { useState } from 'react';
import { capitalizeString } from "../../hooks/useToolsCases";

// Redux
import { useDispatch } from "react-redux";
import { setMultipleInputData } from '../../features/multipleInputSlice';

const MenuProps = {
  PaperProps: {
	style: {
	  maxHeight: 48 * 4.5 + 8,
	  width: 250,
	},
  },
};

export default function MultipleSelectCheckmarks({ users, services, type }) {
  const [selectData, setSelectData] = useState([]);
  const dispatch = useDispatch();

  const handleSelectChange = (e) => {
		const {target: { value }} = e;
		setSelectData(
			typeof value === 'string' ? value.split(',') : value,
		);
  };

  const handleDataIds = (id, type) => {
    dispatch(setMultipleInputData({id, type}));
  };

  return (
	<div className="d-flex justify-content-center">
	  <FormControl sx={{ m: 1, width: 300 }}>
			<InputLabel id={`multiple-checkbox-label${type}`}>{ type === "customers" ? "Clientes" : "Serviços" }</InputLabel>
			<Select
				labelId={`multiple-checkbox-label${type}`}
				id={`multiple-checkbox${type}`}
				multiple
				value={selectData}
				onChange={handleSelectChange}
				input={<OutlinedInput label={ type === "customers" ? "Clientes" : "Serviços" } />}
				renderValue={(selected) => selected.join(', ')}
				MenuProps={MenuProps}        
			>
				{users && users.map((user) => (
					<MenuItem key={user._id} value={user.name} color='red' onClick={() => handleDataIds(user._id, "customer")}>
						<Checkbox checked={selectData.indexOf(user.name) > -1} />
						<ListItemText primary={user.name} secondary={user._id} />
					</MenuItem>
				))}

				{services && services.map((service) => (
					<MenuItem key={service._id} value={ `${capitalizeString(service?.name)}` } color='red' onClick={() => handleDataIds(service._id, "service")}>
						<Checkbox checked={selectData.indexOf(`${capitalizeString(service?.name)}`) > -1} />
						<ListItemText primary={ `${capitalizeString(service?.name)}` } />
					</MenuItem>
				))}
			</Select>
	  </FormControl>    
	</div>
  );
}
