// CSS
import "./Pricing.css";

// Hooks
import { useState, useLayoutEffect } from "react";

// Axios
import axios from "axios";

// Components
import { VideoPlayer, Alert } from "../../components";

// Router
import { useParams } from 'react-router-dom';

// Config
import CONFIG from "../../config/config";

const Pricing = () => {
  const { name } = useParams();

  const [serviceName, setServiceName] = useState('');
  const [serviceData, setServiceData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState({
    title: '',
    message: '',
    type: ''
  })

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "service") setServiceData(data);
        if(name === "management") setServiceName("Sistemas de gestão");
        
        setIsLoading(false);
  
      } catch (error) {
        setNotification({title: "Erro", message: "Erro ao buscar dados", type: "danger"});
      }
    }

    fetchData(`/service/${name}`, "service");
  }, []);

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <div className="container py-3">
        <header>
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 className="display-4 fw-normal text-body-emphasis">{ serviceName && serviceName }</h1>
            <h4 className="fs-5 text-body-secondary">Veja como funciona</h4>
          </div>
        </header>

        <div className="">
          <p className="mb-1 text-center">Vídeo demonstrativo de um sistema de gestão para uma auto lavagem</p>
          <VideoPlayer />

          <p className="mt-5">Todo sistema, pode possuir diferentes níveis de gastos, tais como:</p>

          <ul>
            <li>Hospedagem</li>
            <li>Base de dados</li>
            <li>Serviços online (ex: serviço de e-mail)</li>
          </ul>
          
          <p>Esses gastos somam ao montante final do projeto e podem ser mensais e/ou anuais, variando o valor conforme os requisitos do projeto.</p>
        </div>
        
      </div>
    </>
  )
}

export default Pricing