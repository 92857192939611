// CSS
import "./HomeBlog.css";

// Hooks
import { useState, useLayoutEffect } from "react";

// Config
import CONFIG from "../../../config/config";

// Axios
import axios from "axios";

// Components
import { Post, Loader, Alert } from "../../../components";

const HomeBlog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [notification, setNotification] = useState({})

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "posts") setPosts(data);
        setIsLoading(false);
  
      } catch (error) {    
        setNotification({title: "Erro", message: "Erro ao carregar os posts", type: "danger"});
      }
    }

    fetchData("/posts", "posts");
  }, []);

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <div className='container post-container'>
        <div className="header my-3">
          <h1>Blog</h1>
        </div>

        <div className="row mb-2">
          { isLoading ? <Loader /> : <Post posts={posts}/>}
        </div>
      </div>
    </>
  )
}

export default HomeBlog