// Config
import CONFIG from "../config/config";

const useCheckSession = () => {
  const setDate = localStorage.getItem('setDate');

  if(setDate) {
    const currentTime = Date.now();
    const timeDifference = currentTime - setDate;
    const oneHourInMilliseconds = 60 * 60 * 1000;

    if(timeDifference >= oneHourInMilliseconds) {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('setDate');
      localStorage.removeItem('email');

      window.location.href = CONFIG.HOME_URL;
    }
  }
}

export { useCheckSession }