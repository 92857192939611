// Mui
import ControlPointIcon from '@mui/icons-material/ControlPoint';

// Hooks
import { useState } from 'react';

// Axios
import axios from 'axios';

// Config
import CONFIG from '../../config/config';

// Components
import { Alert } from '../../components';

const CreateService = () => {
  const expirationDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  const token = localStorage.getItem('token');

  const [serviceName, setServiceName] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [notification, setNotification] = useState({});
  const [host, setHost] = useState({
    name: '',
    price: '',
    expirationDate
  });
  const [hosts, setHosts] = useState([]);

  const [database, setDatabase] = useState({
    name: '',
    price: '',
    expirationDate
  });
  const [databases, setDatabases] = useState([]);

  const [extraService, setExtraService] = useState({
    name: '',
    price: '',
    expirationDate
  });
  const [extraServices, setExtraServices] = useState([]);

  const handleHost = (e) => {
    setHost({ ...host, [e.target.name]: e.target.value })
  }

  const handleHosts = (index) => {
    if(index >= 0) {
      setHosts((prevHosts) => prevHosts.filter((host) => host !== hosts[index]));
    }
    else {
      setHosts((prevHosts) => [...prevHosts, host]);
    }

    setHost({...host, name: '', price: ''});
  }

  const handleDatabase = (e) => {
    setDatabase({ ...database, [e.target.name]: e.target.value })
  }

  const handleDatabases = (index) => {
    if(index >= 0) {
      setDatabases((prevDatabases) => prevDatabases.filter((database) => database !== databases[index]));
    }
    else {
      setDatabases((prevDatabases) => [...prevDatabases, database]);
    }

    setDatabase({...database, name: '', price: ''});
  }

  const handleExtraService = (e) => {
    setExtraService({ ...extraService, [e.target.name]: e.target.value })
  }

  const handleExtraServices = (index) => {
    if(index >= 0) {
      setExtraServices((prevExtraServices) => prevExtraServices.filter((extraService) => extraService !== extraServices[index]));
    }
    else {
      setExtraServices((prevExtraServices) => [...prevExtraServices, extraService]);
    }

    setExtraService({...extraService, name: '', price: ''});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNotification({});
    setIsButtonDisabled(true);
    setServiceName('');
    setHosts([]);
    setDatabases([]);
    setExtraServices([]);

    await axios({
      method: 'POST',
      url: `${CONFIG.API_URL}/service`,
      data: { name: serviceName, hosts, databases, extraServices },
      headers: { Authorization: 'Bearer ' + token }
    })
    .then((res) => {
      if(res.status === 201){
        setNotification({title: "Sucesso", message: "Serviço criado com sucesso!", type: "success"});
      }
    })
    .catch((err) => {
      const error = err.response.data.error;
      setNotification({title: "Serviço não cadastrado!", message: error, type: "danger"});
    })

    setIsButtonDisabled(false);
  }

  return (
    <div className="container d-flex flex-column align-items-center">
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}

      <h3 className="mt-2">Cadastrar serviço</h3>

      <form className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Nome</label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder='Nome do serviço'
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
        </div>

        <label className="form-label">Hosts</label>
        <div className="mb-3 input-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder='Nome do serviço'
            value={host.name}
            onChange={handleHost}
          />
          
          <input
            type="number"
            className="form-control"
            name="price"
            placeholder='Preço do serviço'
            value={host.price}
            onChange={handleHost}
          />
          <span className="input-group-text cursor-pointer" onClick={() => handleHosts()}>
            <ControlPointIcon/>
          </span>
        </div>

        <ul className='list-group'>
          {hosts.map((host, index) => (
            <li
              key={index}
              onClick={() => handleHosts(index)}
              className='cursor-pointer delete-item list-group-item'
            >
              { host.name } - R${ host.price }
            </li>
          ))}
        </ul>

        <label className="form-label mt-3">Databases</label>
        <div className="mb-3 input-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder='Nome do banco de dados'
            value={database.name}
            onChange={handleDatabase}
          />
          
          <input
            type="number"
            className="form-control"
            name="price"
            placeholder='Preço do banco de dados'
            value={database.price}
            onChange={handleDatabase}
          />
          <span className="input-group-text cursor-pointer" onClick={() => handleDatabases()}>
            <ControlPointIcon/>
          </span>
        </div>

        <ul className='list-group'>
          {databases.map((database, index) => (
            <li
              key={index}
              onClick={() => handleDatabases(index)}
              className='cursor-pointer delete-item list-group-item'
            >
              { database.name } - R${ database.price }
            </li>
          ))}
        </ul>

        <label className="form-label mt-3">Extra services</label>
        <div className="mb-3 input-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder='Nome do serviço extra'
            value={extraService.name}
            onChange={handleExtraService}
          />
          
          <input
            type="number"
            className="form-control"
            name="price"
            placeholder='Preço do serviço extra'
            value={extraService.price}
            onChange={handleExtraService}
          />
          <span className="input-group-text cursor-pointer" onClick={() => handleExtraServices()}>
            <ControlPointIcon/>
          </span>
        </div>

        <ul className='list-group'>
          {extraServices.map((extraService, index) => (
            <li
              key={index}
              onClick={() => handleExtraServices(index)}
              className='cursor-pointer delete-item list-group-item'
            >
              { extraService.name } - R${ extraService.price }
            </li>
          ))}
        </ul>

        <button
          className="btn btn-primary w-100 mt-3"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
        >
          { !isButtonDisabled ? "Criar serviço" : "Carregando..." }
        </button>
      </form>
    </div>
  )
}

export default CreateService