// Hooks
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Config
import CONFIG from '../../config/config';

const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');

  useEffect(() => {
    if(id === CONFIG.ADMIN_ID) {
      setIsAdmin(true);
    }
  }, [id])

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();

    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('setDate');
    localStorage.removeItem('email');

    return navigate("/", { state: { title: "Logout efetuado com sucesso!", message: "Você está desconectado!", type: "success" } });
  };

  return (
    <header className="p-3 bg-light">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start">
          <a href="/" className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
            <img src="/img/logo/logo-dark-cut.webp" alt="" className="bi me-2" width="50" height="50" />
          </a>

          <ul className="nav nav-pills col-12 col-sm-auto me-sm-auto mb-2 justify-content-center mb-sm-0">
            <li className='nav-item'><a href="/" className={location.pathname === "/" ? `nav-link px-2 active` : `nav-link px-2`}>Home</a></li>
            <li className='nav-item'><a href="/blog" className={location.pathname === "/blog" ? `nav-link px-2 active` : `nav-link px-2`}>Blog</a></li>
            {/* <li className='nav-item'><a href="/about" className={location.pathname === "/about" ? `nav-link px-2 active` : `nav-link px-2`}>Sobre mim</a></li> */}
          </ul>

          <div className="text-end">
            {token ? (
              <div className="dropdown text-end">
                <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="/img/icons/user.webp" alt="Perfil" width="32" height="32" className="rounded-circle" />
                </a>
                
                <ul className="dropdown-menu text-small">
                  <li><a className="dropdown-item" href={ isAdmin ? "/linkservices" : "/myservices" }>{ isAdmin ? "Linkar serviços" : "Meus serviços" }</a></li>
                  {isAdmin && (
                    <>
                      <li><a className="dropdown-item" href="/createuser">Criar usuário</a></li>
                      <li><a className="dropdown-item" href="/createservice">Criar serviço</a></li>
                    </>
                  )}
                  {/* <li><a className="dropdown-item" href="/profile">Perfil</a></li> */}
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#" onClick={(e) => handleLogout(e)}>Sair</a></li>
                </ul>
              </div>
            ) : (
              <>
                <button type="button" className="btn btn-outline-primary me-2" onClick={() => navigate("/login")}>Entrar</button>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header