const removeKebabCase = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const removeSpaceCase = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

const capitalizeString = (str) => {
  return str
  .charAt(0).toUpperCase() + str.slice(1);
};

const formatToBrl = (str) => {
  return "R$" + str
  .toFixed(2).replace('.', ',');
};

export { removeKebabCase, removeSpaceCase, capitalizeString, formatToBrl }