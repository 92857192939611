// CSS
import "./CreateUser.css";

// Components
import { Form } from "../../../components";

const CreateUser = () => {
  return (
		<main className="form-signin w-100 mx-auto">
			<Form type="CreateUser" />
	  </main>
  )
}

export default CreateUser