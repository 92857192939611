import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersId: [],
  servicesId: []
}

export const multipleInputSlice = createSlice({
  name: 'multipleInput',
  initialState,
  reducers: {
		setMultipleInputData: (state, actions) => {
			const { id, type } = actions.payload;
			
			switch (type) {
				case "customer":
					if(state.usersId.includes(id)){
						state.usersId = state.usersId.filter((userId) => userId !== id)
					}
					else {
						state.usersId.push(id);
					}
					break;

				case "service":
					if(state.servicesId.includes(id)){
						state.servicesId = state.servicesId.filter((serviceId) => serviceId !== id)
					}
					else {
						state.servicesId.push(id);
					}
					break;
			
				default:
					break;
			}
		}
  }
});

export const { setMultipleInputData } = multipleInputSlice.actions;

export default multipleInputSlice.reducer;