// CSS
import "./Home.css";

// Hooks
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useLayoutEffect } from "react";

// Axios
import axios from "axios";

// Config
import CONFIG from "../../config/config";

// Components
import {
  Alert,
  Loader,
  ProjectCard,
  SwiperCarousel,
  CareerList
} from "../../components";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [notification, setNotification] = useState({})

  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "projects") setProjects(data);
        setIsLoading(false);
  
      } catch (error) {
        console.log(error);
        setNotification({title: "Erro", message: "Erro ao carregar os projetos", type: "danger"});
      }
    }

    fetchData("/projects", "projects");
  }, []);

  return (
    <>
      {location?.state?.message || location?.state?.title ? (
        <Alert title={location?.state?.title} message={location?.state?.message} type={location?.state?.type} />
      )  : ''}

      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      
      <main>
        <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel" data-bs-theme="light">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="img/slider/Layer3.webp" width="100%" height="100%" className="bd-placeholder-img" />

                <div className="container">
                  <div className="carousel-caption text-start">
                    <h1 className="text-dark text-start">Guilherme Schuch</h1>
                    <p className="text-body-secondary fw-bold">Transformando suas necessidades em soluções digitais.</p>
                    <p><a className="btn btn-lg btn-primary" href="https://wa.link/gxnd90" target="_blank">Contatar</a></p>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="container marketing">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 d-sm-flex d-lg-block align-items-center ">
              <div>
                <img src="img/employee.webp" className="bd-placeholder-img rounded-circle" width={140} height={140} />
              </div>
              <div>
                <h2 className="fw-normal">Eduardo, <span className="text-body-secondary">Assistente administrativo</span></h2>
                <p>Mostrei o sistema de gestão financeira para o meu pai, ele usa fielmente! Tem total controle de todos os seus gastos.</p>
              </div>
            </div>

          </div>
      
          <hr className="featurette-divider" />
      
          <div className="row featurette">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-normal lh-1">Sistemas de gestão</h2>
              <p className="lead">Uma forma eficiente e inteligente de gerenciar o seu negócio automatizando processos, otimizando recursos e fornecendo dados valiosos em tempo real.</p>
              <div className="btn-group col-md-6 col-12 my-3">
                <button className="btn btn-primary" onClick={() => navigate("/pricing/management")}>Ver mais</button>
              </div>
            </div>
            <div className="col-md-5 shadow p-0">
              <img
                src="img/site/siteGlobal.webp"
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              />
            </div>
          </div>
      
          <hr className="featurette-divider" />
      
          <div className="row featurette">
            <div className="col-md-7 order-md-2">
              <h2 className="featurette-heading fw-normal lh-1">Landing pages</h2>
              <p className="lead">Conquiste mais clientes com Landing Pages persuasivas projetadas para atrair a atenção do seu público-alvo, envolvê-los com conteúdo relevante e persuasivo, e motivá-los a tomar a ação desejada.</p>
            </div>
            <div className="col-md-5 order-md-1 shadow p-0">
              <img
                src="img/site/siteControle.webp"
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              />
            </div>
          </div>
      
          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-normal lh-1">Sistemas financeiros</h2>
              <p className="lead">Modernize e simplifique a gestão financeira da sua empresa. Desfrute de uma automação de processos que elimina a necessidade de tarefas manuais e repetitivas.</p>
            </div>
            <div className="col-md-5 shadow p-0">
              <img
                src="img/site/siteFinanceiro.webp"
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row d-flex justify-content-center">
            <div className="text-center mb-4">
              <h1 className="m-0 p-0">Projetos</h1>
              <span className="small-text d-none d-lg-block">(Clique e arraste para ver mais)</span>
            </div>

            <div className="projects-container">
              <div className="d-none d-lg-block shadow">
                {isLoading ? <Loader /> : (
                  <SwiperCarousel data={projects} type="card" />
                )}
              </div>

              <div className="d-flex flex-column align-items-center d-lg-none">
                {isLoading ? <Loader /> : (
                  <>
                    {projects.map((project, index) => (
                      <ProjectCard key={index} project={project} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>


          <hr className="featurette-divider" />

          <div className="row d-flex justify-content-center">
            <div className="companies text-center mb-4">
              <h1>Minha carreira até agora</h1>
            </div>

            <div className="col-lg-6">
              <CareerList />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home