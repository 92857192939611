// CSS
import "./LinkServices.css";

// Hooks
import { useState, useLayoutEffect, useEffect } from "react";

// Axios
import axios from "axios";

// Router
import { useNavigate } from 'react-router-dom';

// Components
import { MultiSelect, Alert } from "../../components";

// Config
import CONFIG from "../../config/config";

// Redux
import { useSelector } from "react-redux";

const LinkServices = () => {
  const [users, setUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [notification, setNotification] = useState({})
  const [isAdmin, setIsAdmin] = useState(false);

  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "users") setUsers(data);
        if(type === "services") setServices(data);
  
      } catch (error) {
        console.log(error);
        setNotification({title: "Erro", message: "Erro ao buscar dados", type: "danger"});
      }
    }

    fetchData("/users", "users");
    fetchData("/services", "services");
  }, []);

  useEffect(() => {
    if(id === CONFIG.ADMIN_ID) {
      setIsAdmin(true);
    }
  }, [id])

  const navigate = useNavigate();  


  const { usersId, servicesId } = useSelector((state) => state.multipleInput);

  if(!isAdmin){
    return navigate("/", { state: { title: "Erro", message: "Você não tem permissão para acessar essa página!", type: "danger" } });
  }

  const linkServices = (e) => {
		e.preventDefault();
    setNotification({});

		axios({
			method: 'PUT',
			url: `${CONFIG.API_URL}/user/services/${usersId[0]}`,
			data: { services: servicesId }, 
      headers: { Authorization: 'Bearer ' + token }
    })
    .then((res) => {
			if(res.status === 200) {
				setNotification({title: "Sucesso", message: "Serviço(s) atualizado com sucesso!", type: "success"});
			}
		})
    .catch((err) => {
      setNotification({title: "Erro", message: "Erro ao atualizar serviços", type: "danger"});
    })
	}

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}

      <div className="container linkServicesContainer">
        <div className="row mt-3 justify-content-center">
          <MultiSelect users={users} type="customers" />
          <MultiSelect services={services} type="services" />
          
          <button type="button" onClick={linkServices} style={{ width: 300 }} className="btn btn-outline-primary">Linkar</button>
        </div>
      </div>
    </>
  )
}

export default LinkServices