// Hooks
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

// Axios
import axios from "axios";

// Config
import CONFIG from "../../config/config";

// Components
import Alert from "../Alert/Alert";

const Form = ({ type }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [notification, setNotification] = useState({});
  const [user, setUser] = useState({
    email: '',
    password: '',
    name: '',
  });
  
  const navigate = useNavigate();

  const handleUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNotification({});
    setIsButtonDisabled(true);

    if(type === "CreateUser") {
      await axios({
        method: 'POST',
        url: `${CONFIG.API_URL}/user/register`,
        data: { name: user.name, email: user.email, password: user.password },
      })
      .then((res) => {
        if(res.status === 201){
          const { token } = res.data;
          localStorage.setItem('token', token);

          navigate("/", { state: { title: "Cadastro realizado com sucesso!" } });
        }
      })
      .catch((err) => {
        const error = err.response.data.error;
        setNotification({title: "Cadastro não efetuado!", message: error, type: "danger"});
      })
    }
    else if(type === "Login") {
      await axios({
        method: 'POST',
        url: `${CONFIG.API_URL}/user/login`,
        data: { email: user.email, password: user.password },
      })
      .then((res) => {
        if(res.status === 200 && !res.data.error) {
          const { token } = res.data;
          const { id }    = res.data;
          const { email } = res.data;

          const setDate = Date.now();

          localStorage.setItem('token', token);
          localStorage.setItem('id', id);
          localStorage.setItem('setDate', setDate);
          localStorage.setItem('email', email);

          navigate((id === CONFIG.ADMIN_ID ? "/linkservices" : "/myservices"), { state: { title: "Login efetuado com sucesso!", message: res.data.msg } });
        }
      })
      .catch((err) => {
        const error = err.response.data.error;
        setNotification({title: "Login não efetuado!", message: error, type: "danger"});
      });
    }

    setIsButtonDisabled(false);
  }

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <form onSubmit={handleSubmit}>
        <h1 className="h3 mb-3 fw-normal">{ type === "CreateUser" ? "Criar usuário" : "Entrar" }</h1>

        {type === "CreateUser" && (
          <div className="form-floating">
            <input type="text" className="form-control mb-2 rounded-2" name="name" value={user.name} onChange={(e) => handleUserChange(e)} />
            <label>Nome</label>
          </div>
        )}

        <div className="form-floating">
          <input type="email" className="form-control mb-2 rounded-2" name="email" value={user.email} onChange={(e) => handleUserChange(e)} />
          <label>Email</label>
        </div>

        <div className="form-floating">
          <input type="password" className="form-control rounded-2" id="floatingPassword" name="password" value={user.password} onChange={(e) => handleUserChange(e)} />
          <label>Senha</label>
        </div>

        <button
          className="btn btn-primary w-100 py-2"
          disabled={isButtonDisabled}
        >
          { !isButtonDisabled ? (type === "CreateUser" ? "Criar" : "Entrar") : "Carregando..." }
        </button>
      </form>
    </>
  )
}

export default Form