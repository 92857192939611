// Mui
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from '@mui/material';

export default function CareerList() {
  return (
    <List sx={{ width: '100%' }} className='shadow rounded'>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt="800K"
            sx={{border: "1px solid #bdbdbd"}}
            src="img/career/800k.webp"
          />
        </ListItemAvatar>
        <ListItemText
          primary="800K"
          secondary={
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Suporte
              </Typography>
              {" — Jan 2023 até Nov 2023"}
            </>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" />

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt="Freelancer"
            sx={{border: "1px solid #bdbdbd"}}
            src="img/logo/logo-dark-cut.webp"
          />
        </ListItemAvatar>
        <ListItemText
          primary="Freelancer"
          secondary={
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Freelancer
              </Typography>
              {" — Nov 2023"}
            </>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" />
      
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt="Flanp"
            sx={{border: "1px solid #bdbdbd"}}
            src="img/career/flanp.webp"
          />
        </ListItemAvatar>
        <ListItemText
          primary="Flanp"
          secondary={
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Desenvolvedor de software
              </Typography>
              {' — Abr 2024'}
            </>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" />

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt="FCP"
            sx={{border: "1px solid #bdbdbd"}}
            src="img/career/fcp.webp"
          />
        </ListItemAvatar>
        <ListItemText
          primary="FCP"
          secondary={
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Desenvolvedor de software
              </Typography>
              {' — Junho 2024'}
            </>
          }
        />
      </ListItem>
    </List>
  );
}