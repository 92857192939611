// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./SwiperCarousel.css";

// Hooks
import { useState } from "react";

// Components
import ProjectCard from "../ProjectCard/ProjectCard";

const SwiperCarousel = ({ data, type }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  window.addEventListener("resize", function(e) {
    if(window.innerWidth) setWindowSize(window.innerWidth);
  }, false);

  return (
    <div className="swiperCarouselContainer p-4">
      <Swiper
        modules={type === "page" ? [Pagination, Autoplay] : [Pagination]}
        className="swiperCarousel"
        slidesPerView={windowSize < 992 ? "1" : "3"}
        loop={true}
        direction={windowSize < 992 ? "vertical" : "horizontal"}
        autoplay={{delay: 3000}}
        pagination={type === "page" ? {clickable: true} : {type: "progressbar"}}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            className="slideItem d-flex d-lg-block justify-content-center align-items-center"
          >
            {type === "page" && (
              <img src={item} alt="Imagem não disponível" />
            )}

            {type === "card" && (
              <ProjectCard project={item} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SwiperCarousel