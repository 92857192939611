// CSS
import "./Login.css";

// Components
import { Form } from "../../../components";

const Login = () => {
  return (
		<main className="form-signin w-100 mx-auto">
		  <Form type="Login" />
	  </main>
  )
}

export default Login