// CSS
import "./Footer.css";

// Mui
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="container footer-container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <img src="/img/logo/logo-dark-cut.webp" alt="" className="bi" width="50" height="50" />
          <span className=" ms-1 text-body-secondary">Guilherme Schuch © { year }</span>
        </div>
    
        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3"><a href="https://www.instagram.com/guigui.schuch/" target="_blank"><InstagramIcon sx={{color: "#000000", fontSize: "24px" }} /></a></li>
          <li className="ms-3"><a href="https://github.com/GuilhermeSchuch" target="_blank"><GitHubIcon sx={{color: "#000000"}} /></a></li>
          <li className="ms-3"><a href="https://www.linkedin.com/in/guilhermeschuch2005/" target="_blank"><LinkedInIcon sx={{color: "#000000"}} /></a></li>
        </ul>
      </footer>
    </div>
  )
}

export default Footer