// CSS
import "./ProjectCard.css";

const ProjectCard = ({ project }) => {
	return (
		<>
			<div className={`project-card`}>
				<div className="project-image">
					{project?.name === "Listinha" && (
						<img src="/img/projects/listinha.webp" alt="Listinha" />
					)}
					{project?.name === "Auto Lavagem" && (
						<img src="/img/projects/autoLavagem.webp" alt="Auto Lavagem" />
					)}  
					{project?.name === "Global" && (
						<img src="/img/projects/global.webp" alt="Global" />
					)}
					{project?.name === "MP3 Player" && (
						<img src="/img/projects/mp3player.webp" alt="MP3 Player" />
					)}
				</div>
	
				<div className="project-info text-center">
					<h3 className="project-title mx-2">{ project?.name }</h3>
					<p className="project-description mx-2">{ project?.desc }</p>

					<ul className="project-tags d-flex justify-content-center">
						{project?.tags?.map((tag, index) => (
							<li key={index}><span className="tag">{ tag }</span></li>
						))}
					</ul>

					<div className="project-links mb-2 d-flex justify-content-center">
						{ project?.name !== "Global" && <a href={`project/${project?.name}`} className="projectCardBtn">Ver Projeto</a> }
						{ project?.github && <a target="_blank" href={ project?.github } className="projectCardBtn">GitHub</a> }
					</div>
				</div>
			</div>
		</>
	)
}

export default ProjectCard