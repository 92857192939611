// CSS
import "./ProjectPage.css";

// Hooks
import { useParams } from 'react-router-dom';

// Components
import { VideoPlayer, SwiperCarousel } from "../../components";

const ProjectPage = () => {
  const { name } = useParams();

  const images = [
    "/img/projectPage/listinha/home.webp",
    "/img/projectPage/listinha/items.webp",
    "/img/projectPage/listinha/sidemenu.webp",
    "/img/projectPage/listinha/settings.webp",
    "/img/projectPage/listinha/language.webp"
  ];

  return (
    <div className="container overflow-hidden mt-3">
      { name === "Listinha" && <h1 className="text-center">Descrição do APP</h1> }
      { name === "Auto Lavagem" && <h1 className="text-center">Descrição do Sistema</h1> }
      { name === "MP3 Player" && <h1 className="text-center">Descrição do Software</h1> }

      <div className="row shadow rounded my-3">
        {name === "Listinha" && (
          <>
            <div className="d-none d-lg-block">
              <SwiperCarousel data={images} type="page" />
            </div>

            <div className="d-flex justify-content-center d-lg-none p-4">
              <img
                src={images[0]}
                alt="Imagem não disponível"
                width={400}
              />
            </div>
          </>
        )}
        { name === "Auto Lavagem" && <VideoPlayer /> }
        { name === "MP3 Player" && (
          <div className="d-flex justify-content-center p-4">
            <img
              src="/img/projects/mp3player.webp"
              alt="Imagem não disponível"
              className="img-fluid"
            />
          </div>
        ) }
      </div>

      <div className="desc">
        <div className="mt-3">
          <h2>Sobre:</h2>
          { name === "Listinha" && <p>Pode se dizer adeus para as listinhas feitas a mão e nunca mais precisar se preocupar em esquecê-las em casa. Com o Listinha, o usuário possui todo o gerenciamento de listas e itens no próprio celular.</p> }
          { name === "Auto Lavagem" && <p>Sistema completo para gerenciamento de uma Auto Lavagem. Projeto Freelancer com uma demora total de 3 meses para conclusão do sistema.</p> }
          { name === "MP3 Player" && <p>Software tocador de música completo, com atalhos no teclado que funcionam com o software minimizado.</p> }
        </div>

        <div className="mt-3">
          <h2>Funcionalidades:</h2>
          <ul>
            {name === "Listinha" && (
              <>
                <li>Gerenciamento de listas</li>
                <li>Compatibilidade com 3 idiomas</li>
                <li>Temas alternativos (em breve)</li>
              </>
            )}

            {name === "Auto Lavagem" && (
              <>
                <li>Gerenciamento de clientes</li>
                <li>Gerenciamento de funcionários</li>
                <li>Gerenciamento de carros</li>
                <li>Gerenciamento de serviços</li>
                <li>Integração financeira</li>
                <li>Geração de planilhas</li>
              </>
            )}

            {name === "MP3 Player" && (
              <>
                <li>Ler e reproduzir arquivos <code>.mp3</code></li>
                <li>Buscar letras das músicas selecionadas</li>
                <li>Atalhos de teclado para controlar a música atual tocando</li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProjectPage